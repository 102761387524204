





































/** デコレータ利用のためComponent, Vueをimport **/
import { Component, Vue } from 'vue-property-decorator';
/** 画面で必要なVuexの各stateをimport **/
import hexabaseState from '@/store/hexabase';
import searchState from '@/store/search';
/** ドメイン単位の設定ファイルから必要な設定をimport **/
import {
  documentDBName,
  documentDBDeleteStatusName,
  documentDBNotSearchStatusId,
  documentDBStatusDisplayId
} from '@/domains/documentManagement/constants/constants';
/** 画面単位の設定ファイルから必要な設定をimport **/
import localDisplayConfig from '@/domains/documentManagement/constants/documentCsvOutput';
/** 画面で利用するモデルファイルをimport **/
import DocumentModel from '@/domains/documentManagement/models/documentModel';
/** 画面で利用するバックエンド設定のimport **/
import { FieldChildrenProp } from '@/services/hexabase/types';
/** 画面で利用するコンポーネントをimport 下部に存在する＠Componentにも記載する **/
import Header from '@/components/common/modules/Header.vue';
import SideMenu from '@/components/common/modules/SideMenu.vue';
import PageInfoBar from '@/components/common/modules/PageInfoBar.vue';
import SearchPanel from '@/components/common/modules/csv/CsvSearchPanel.vue';
import Dialog from '@/components/common/modules/Dialog.vue';
/** HexaBaseリソース（データストアorデータレポート） **/
export type HexaBaseResouce = 'DATASTORE' | 'REPORT';

@Component({
  components: {
    Header,
    SideMenu,
    PageInfoBar,
    SearchPanel,
    Dialog
  }
})
export default class DocumentCsvOutput extends Vue {
  // 各種設定値をリテラル宣言（ ※コピー後、必要に応じてリテラル値を変更 ）
  readonly baseTranDatabaseName = documentDBName; // 対象のデータベース名(ja)
  readonly removeStatusName = documentDBDeleteStatusName; // 検索条件のステータス・コンボボックスの項目から省くステータス
  // ===============================================================
  // モデルをセット
  readonly basicModel = new DocumentModel();
  // アプリIDをセット
  readonly projectId = hexabaseState.applicationId;
  // dbID格納プロパティ
  private mainDsId = '';
  // ダイアログプロパティ
  private apiDialog = false;
  private apiDialogBodyText = '';
  private apiDialogMaxWidth = '800';
  private apiDialogTableColumn: Array<{ [k: string]: string | number }> = [];
  private apiDialogErrData: Array<{ [k: string]: string | any }> = [];
  // マスタデータ関連格納プロパティ
  private masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};
  // アイテム取得時Param
  private csvPayload = {
    conditions: [] as Array<{ [key: string]: string | string[] | boolean }>,
    per_page: 0,
    page: 1,
    use_display_id: true,
    format: 'csv',
    sort_fields: localDisplayConfig.DefaultSort
  };
  private totalItemPayload = {
    conditions: [] as Array<{ [key: string]: string | string[] | boolean }>,
    per_page: 0,
    page: 1,
    return_count_only: true
  };

  // 各入力フィールド情報格納プロパティ
  private searchFieldsData: Array<FieldChildrenProp> = [];
  private searchFieldCols = '';
  private statusList: any = {};
  // 一覧表示部分に使用
  private fieldsData: any = {};
  // 対象のHexaBaseリソース（データストアorデータレポート）
  private hexaBaseResouce: HexaBaseResouce = 'DATASTORE';

  async created() {
    try {
      // ローディングをセット
      this.$store.commit('hexabase/setIsLoading', true);
      // データストア情報セット
      if (this.hexaBaseResouce === 'DATASTORE') {
        this.mainDsId = hexabaseState.datastoreIds[this.baseTranDatabaseName];
      } else {
        this.mainDsId = 'XXXXXXXXX';
      }
      const [fieldsData, statusList] = await Promise.all([
        this.basicModel.getField(this.projectId, this.mainDsId),
        this.basicModel.getStatusList(this.projectId, this.mainDsId)
      ]);
      // マスターデータを取得しグローバル変数に格納
      await this.basicModel.getMasterData();
      this.masterData = this.basicModel.getMaster();
      // 自動生成コンポーネント用にプロパティー設定
      this.fieldsData = fieldsData;
      this.searchFieldsData = await this.basicModel.createSearchConf(
        this.fieldsData,
        this.masterData,
        localDisplayConfig.searchForm
      );
      this.searchFieldCols = `${localDisplayConfig.searchPanelColumnWidth}% `.repeat(
        100 / localDisplayConfig.searchPanelColumnWidth
      ); //検索フォームは表示を均等にするため固定
      // ステータス検索用にステータスリストをマージ
      this.statusList = statusList;
      if (this.statusList.length > 0) {
        const deleteStatusIndex = this.statusList[0].statuses!.findIndex(
          (status: { status_name: string }) => status.status_name === this.removeStatusName
        );
        if (deleteStatusIndex > 0) {
          this.statusList[0].statuses!.splice(deleteStatusIndex, 1);
        }

        this.searchFieldsData = this.basicModel.setStatusBySelectProp(
          this.searchFieldsData,
          this.statusList[0].statuses!
        );
      }

      // 初期表示データ取得・作成
      // 検索Stateが存在していたら検索項目に値をセットする
      const stateCondition =
        searchState.searchCondition !== undefined ? searchState.searchCondition : [];
      for (const key in stateCondition) {
        const setConditionIndex = this.searchFieldsData.findIndex(
          field => field.display_id === stateCondition[key].id
        );
        if (setConditionIndex >= 0) {
          const dataType = this.searchFieldsData[setConditionIndex].dataType;
          switch (dataType) {
            case 'dslookup':
              this.searchFieldsData[setConditionIndex].props!.value = {
                item_id: stateCondition[key].search_value[0]
              };
              break;
            case 'status':
              this.searchFieldsData[setConditionIndex].props!.value =
                stateCondition[key].search_value[0];
              break;
            case 'datetime':
              this.searchFieldsData[setConditionIndex].props!.valueFrom =
                stateCondition[key].search_value[0];
              this.searchFieldsData[setConditionIndex].props!.valueTo =
                stateCondition[key].search_value[1];
              break;
            default:
              this.searchFieldsData[setConditionIndex].props!.value =
                stateCondition[key].search_value[0];
              break;
          }
        }
      }
    } catch (e) {
      this.setError(e as string | object);
      console.log(e);
    } finally {
      hexabaseState.setIsLoading(false);
    }
  }

  /**
   * CSV出力するアイテムを取得する
   * @param {array} condition - 検索条件を指定する
   * @returns {void} 各内部プロパティに情報セット
   */
  async getCsvItems(condition?: Array<{ [key: string]: string[] }>) {
    if (condition !== undefined && condition.length > 0) {
      for (const key in searchState.searchCondition) {
        const findData = condition.find(
          recode => recode.id === searchState.searchCondition![key].id
        );
        if (!findData) {
          condition.push(searchState.searchCondition[key]);
        } else {
          const setConditionIndex = this.searchFieldsData.findIndex(
            field => field.display_id === searchState.searchCondition[key].id
          );
          if (setConditionIndex >= 0) {
            const componentType = this.searchFieldsData[setConditionIndex].component;
            switch (componentType) {
              case 'DatePickerRangeArea':
                if (searchState.searchCondition[key].search_value[0] && !findData.search_value[0]) {
                  findData.search_value[0] = searchState.searchCondition[key].search_value[0];
                }
                if (searchState.searchCondition[key].search_value[1] && !findData.search_value[1]) {
                  findData.search_value[1] = searchState.searchCondition[key].search_value[1];
                }
                break;
              default:
                break;
            }
          }
        }
      }
      searchState.setConditions(condition);
    }

    if (condition !== undefined) {
      // conditionsに固定条件を付与するためconcatで参照渡しを回避する
      this.csvPayload['conditions'] = condition.concat();
      this.totalItemPayload['conditions'] = condition.concat();
    }

    // 検索で不要なステータスを検索条件に追加し、検索結果から除外する
    this.csvPayload['conditions'].push({
      id: documentDBStatusDisplayId,
      search_value: documentDBNotSearchStatusId,
      not_match: true
    });
    this.totalItemPayload['conditions'].push({
      id: documentDBStatusDisplayId,
      search_value: documentDBNotSearchStatusId,
      not_match: true
    });

    try {
      // ローディングをセット
      this.$store.commit('hexabase/setIsLoading', true);

      let result: any;
      if (this.hexaBaseResouce === 'DATASTORE') {
        result = await this.basicModel.getItemSearch(
          this.projectId,
          this.mainDsId,
          this.totalItemPayload
        );
      } else {
        result = await this.basicModel.getReports(
          this.projectId,
          this.mainDsId,
          this.totalItemPayload
        );
      }

      if (result.totalItems === -1) {
        this.apiDialogBodyText = localDisplayConfig.csvOutputTextTimeout;
      } else {
        if (result.totalItems === 0) {
          this.apiDialogBodyText = localDisplayConfig.csvOutputTextNotFound;
        } else {
          if (this.hexaBaseResouce === 'DATASTORE') {
            await this.basicModel.getItemSearchCsv(
              this.projectId,
              this.mainDsId,
              this.csvPayload,
              localDisplayConfig.csvFileName,
              'SJIS'
            );
          } else {
            await this.basicModel.getReportsCsv(
              this.projectId,
              this.mainDsId,
              this.csvPayload,
              localDisplayConfig.csvFileName,
              undefined,
              'SJIS'
            );
          }
          this.apiDialogBodyText = localDisplayConfig.csvOutputTextSuccess.replace(
            '${totalItems}',
            result.totalItems
          );
        }
      }
    } catch (e) {
      this.setError(e as string | object);
      console.log(e);
    } finally {
      hexabaseState.setIsLoading(false);
      this.apiDialog = true;
    }
  }

  /**
   * 検索パネルにて「リセット」ボタン押下時
   * 内部プロパティの検索情報を消去する
   * @returns void 内部プロパティ消去
   */
  private resetSearchForm(): void {
    this.csvPayload.conditions.splice(0);
    this.totalItemPayload.conditions.splice(0);
  }

  /**
   * 確認ダイアログ閉じる処理
   * @returns void - 内部プロパティ変更
   */
  private async confirmOpratipon() {
    this.apiDialog = false;
    this.apiDialogTableColumn = [];
    this.apiDialogErrData = [];
  }

  /**
   * API実行結果から受け取ったエラーメッセージを共通のエラーダイアログに表示する
   * @param errorMessage {string | object } - API実行結果から受け取ったエラーメッセージ
   * @returns {void}
   */
  setError(errorMessage: string | object): void {
    if (typeof errorMessage === 'object') errorMessage = this.basicModel.getDefaultErrorMessage();
    // エラーをキャッチした際共通エラーダイアログにセット
    this.$store.commit('hexabase/setIsError', true);
    this.$store.commit('hexabase/setErrorMessage', errorMessage);
  }
}
