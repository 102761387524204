/**
 * 文書管理 CSV出力画面定義ファイル
 * 概要
 * 画面単位でのフロント定義について記載する
 * 更新履歴
 * 2022/03/06 追加 尾坂
 */

/**
 * 検索画面の検索項目について定義する
 * ※データベース参照型を利用する場合は明示的な指定が必要となる
 * グリッド指定で表示位置を指定する
 * col: X座標, row: Y座標, sizeX: 横幅, sizeY: 縦幅
 */
const searchForm = [
  {
    display_id: 'create_date',
    component: 'DatePickerRangeArea',
    props: {
      nameFrom: 'create_date',
      nameTo: 'create_date'
    },
    col: 2,
    row: 1,
    sizeX: 6,
    sizeY: 1
  },
  {
    display_id: 'employee_id',
    component: 'MasterSelectControl',
    col: 1,
    dblookup: 'm_employee',
    props: {
      leftSideInputName: 'employee_id',
      rightSideInputName: 'name',
      masterId: 'id',
      dialogTitle: '社員選択',
      label: '登録者',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true
    },
    row: 0,
    sizeX: 6,
    sizeY: 1
  },
  {
    display_id: 'document_category',
    component: 'MasterSelectControl',
    dblookup: 'document_category',
    col: 0,
    row: 0,
    sizeX: 6,
    sizeY: 1,
    props: {
      leftSideInputName: 'document_category',
      rightSideInputName: 'category_name',
      masterId: 'category_id',
      dialogTitle: 'カテゴリ選択',
      label: 'カテゴリ',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true
    }
  },
  {
    display_id: 'doc_name',
    col: 3,
    row: 1,
    sizeX: 6,
    sizeY: 1
  }
];

/** 一覧取得時のデフォルトソート **/
const DefaultSort: Array<{ [k: string]: string }> = [{ id: 'doc_id', order: 'asc' }];

/** 検索パネルのGrid1区間の横幅（%） **/
const searchPanelColumnWidth = 5;

/** CSVダウンロードファイル名 **/
const csvFileName = 'download';

/** CSVダウンロード時のダイアログ */
const csvOutputTextTimeout = '件数の取得がタイムアウトしました。条件を絞り込んで検索してください。';
const csvOutputTextNotFound = '対象のデータが存在しませんでした。';
const csvOutputTextSuccess = '正常に処理しました。\n出力データ：${totalItems}行';

export default {
  searchForm,
  DefaultSort,
  searchPanelColumnWidth,
  csvFileName,
  csvOutputTextTimeout,
  csvOutputTextNotFound,
  csvOutputTextSuccess
};
